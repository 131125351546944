import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa"
import parse from "html-react-parser"
import dayjs from "dayjs"

import CallToAction from "../components/calltoaction"
import Layout from "../components/layout"
import PageBanner from "../components/pagebanner"
import Seo from "../components/seo"
import RecentPosts from "../components/blog/recentposts"
import Funnel from "../components/forms/funnel"
import ServicesOverview from "../components/homepage/servicesoverview"

interface BlogPostProps {
  data: any;
}

const BlogPost: React.FC<BlogPostProps> = ({ data }) => {
  const {previous, post, next} = data;
  
  const postContent = post.content ? parse(post.content.split("\n\n").map((para) => `<p>${para}</p>`).join("")) : (<div></div>);
  
  return (
    <Layout>
      <PageBanner>{post.title}</PageBanner>
      
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        
        <section className="single-page-section">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <div className="single-page-content">
                  <header className="entry-header">
                    
                    <p className="entry-meta">
                      <small className="text-muted irdate">Posted on {dayjs(post.postDate).format("MMM D YYYY")}<br />
                        <span>
                          Written by <strong>PlexTech Marketing</strong>
                        </span>
                      </small>
                    </p>
                    
                    <div className="post-image">
                      <GatsbyImage image={post.image.src.childImageSharp.gatsbyImageData} alt={post.image.name} />
                    </div>

                  </header>

                  <div className="entry-content">
                    {postContent}
                  </div>
                                        
                  <footer className="entry-footer clear-both">
                    <nav aria-label="Page navigation example">
                      <ul className="side-by-side">
                        {previous &&
                          (<li className="page-item prevItemLeft">
                            <Link to={`/${previous.slug}`} className="btn btn-primary">
                              <FaArrowCircleLeft /> {previous.title}
                            </Link>
                          </li>)}
                        {next &&  
                          (<li className="page-item nextItemRight">
                            <Link to={`/${next.slug}`} className="btn btn-primary">
                              {next.title} <FaArrowCircleRight />
                            </Link>
                          </li>)}
                      </ul>
                    </nav>
                  </footer>
                </div>
              </div>
              
              <div className="col-md-4 post-sidebar">
                <RecentPosts />
                
                <CallToAction baseName="cta-bp-side-1" hasWrapper={false} />
              </div>
              
            </div>
          </div>
        </section>
        
      </article>
      
      <div className="lightblue-bg blog-post-form">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-md-offset-3 text-center">
              <Funnel baseName="blog-post-form" />
            </div>
          </div>
        </div>
      </div>
      
      <br /><br />
      
      <ServicesOverview />
      
    </Layout>
  );
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    post: blogpostsJson(slug: { eq: $id }) {
      slug,
      content,
      title,
      postDate,
      image {
        name,
        src {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
    previous: blogpostsJson(slug: { eq: $previousPostId }) {
      slug,
      title
    }
    next: blogpostsJson(slug: { eq: $nextPostId }) {
      slug,
      title
    }
  }
`

export const Head = ({ data }) => <Seo title={data.post.title} type="article" url={data.post.uri} seo={data.post.seo} />
