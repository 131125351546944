import * as React from "react"
import { useStaticQuery, graphql, navigate, Link } from "gatsby"

const RecentPosts = () => {
  const { allBlogpostsJson } = useStaticQuery(graphql`
    query RecentPostQuery {
      allBlogpostsJson(sort: { postDate: DESC }, limit: 10) {
        edges {
          node {
            title,
            slug,
            content,
            postDate
          }
        }
      }
    }
  `)
  
  return (
    <div>
      <h2>Recent Posts</h2>
      
      <ul>
        {allBlogpostsJson && allBlogpostsJson.edges.map((item: any) => (
          <li key={item.node.slug}><Link to={`/${item.node.slug}`}>{item.node.title}</Link></li>
        ))}
      </ul>
    </div>
  )
}

export default RecentPosts
