import React from "react"
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"
import { FaArrowRight } from "react-icons/fa"

const ServicesOverview = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 col-md-offset-3 text-center fh5co-heading">
          <h2>Services We Provide</h2>
          
          <p>We cater to the problems and issues of small to mid sized businesses.</p>
        </div>
      </div>
      
      <div className="row">
        <div className="col-md-4 text-center item-block animate-box fadeIn animated-fast">
          <div className="preview-casestudy">
            <StaticImage src="../../images/stock-gf2db427ef_1280.jpg" alt="Marketing and Advertising" width={1280} height={960} />
          </div>
          
          <blockquote>
            <h3>Marketing &amp; Advertising</h3>
            <p>We use a statistics first approach to identify where you are losing sales. Statistics help us reliably get inside the mind of your clients.</p>
            
            <Link to="/services#marketing" className="btn btn-primary btn-outline with-arrow btn-sm">View Details <FaArrowRight /></Link>
          </blockquote>
        </div>
        
        <div className="col-md-4 text-center item-block animate-box fadeIn animated-fast">
          <div className="preview-casestudy">
            <StaticImage src="../../images/ecommerce-gb330ab439_1280.jpg" alt="Ecommerce Optimization" width={1280} height={960} />
          </div>
          
          <blockquote>
            <h3>Ecommerce Optimization</h3>
            <p>Are people abandoning their carts before paying? Ask us to help you find out why.</p>
            
            <Link to="/services#ecommerce-optimization" className="btn btn-primary btn-outline with-arrow btn-sm">View Details <FaArrowRight /></Link>
          </blockquote>
        </div>
        
        <div className="col-md-4 text-center item-block animate-box fadeIn animated-fast">
          <div className="preview-casestudy">
            <StaticImage src="../../images/office-g399e9a00d_1280.jpg" alt="Site Optimization" width={1280} height={960} />
          </div>
          
          <blockquote>
            <h3>Site Optimization</h3>
            <p>Every single second longer a page takes to load loses increasingly more customers. Your plugin heavy site might look great but is it costing you? Find out how we fix it.</p>
            
            <Link to="services#site-optimization" className="btn btn-primary btn-outline with-arrow btn-sm">View Details <FaArrowRight /></Link>
          </blockquote>
        </div>
      </div>
    </div>
  );
}

export default ServicesOverview
