import React, { useEffect, useState } from "react"
import { FaArrowRight } from "react-icons/fa"

import ContactUsForm from "./forms/contactus"

interface CallToActionProps {
  baseName: string;
  hasWrapper: boolean;
}

const CallToAction: React.FC<CallToActionProps> = ({ baseName, hasWrapper }) => {
  const [isContactFormOpen, setIsContactFormOpen] = useState(false);
  
  const internalComponents = [
    <h2 key="cta-header">No Commitment Consultation</h2>,
    <p key="cta-para">Just send us your email and we&apos;ll contact you to put together our recommendations</p>,
    <button key="cta-btn" className="btn btn-primary btn-outline with-arrow btn-sm" onClick={() => setIsContactFormOpen(true)}>Start Now <FaArrowRight /></button>,
  ];
  
  return (
    <React.Fragment>
      {hasWrapper ?
        
        <div className="container cta-panel">
          <div className="row">
            <div className="col-md-6 col-md-offset-3 text-center fh5co-heading">
              {internalComponents}
            </div>
          </div>
        </div> :
        
        <div className="cta-panel text-center">
          {internalComponents}
        </div>}
      
      <ContactUsForm baseName={baseName} isOpen={isContactFormOpen} fnClose={() => setIsContactFormOpen(false)} />
    </React.Fragment>
  );
}

export default CallToAction
